import * as Sentry from '@sentry/react';
import { isLocalhost, isProd } from 'shared/utils/consts';
import { DEBUG_DATA } from 'shared/utils/debug';
import { config as trackingConfig } from 'shared/utils/trackingConfig';

export const configureSentry = () => {
  Sentry.init({
    dsn: trackingConfig.sentryDSN,
    // see @https://docs.sentry.io/platforms/javascript/configuration/integrations/browsertracing/
    integrations: isLocalhost() ? [Sentry.browserTracingIntegration()] : [],
    tracesSampleRate: isLocalhost() ? 1.0 : undefined,
    tracePropagationTargets: isLocalhost() ? ['localhost'] : undefined,
  });
};

// Disable console.log in production
// The condition is not same, check if the app is built by `npm run build` and env is prod
export const disableConsoleInProd = () => {
  if (import.meta.env.NODE_ENV === 'production' && isProd()) {
    console.log = () => {};
  }
};

export const setupGlobalDebugging = () => {
  if (typeof window !== 'undefined') {
    window.__DEBUG__ = DEBUG_DATA;

    // see @https://vite.dev/guide/build.html#load-error-handling
    // TODO: Optimize this error handling by implementing https://agentsonly.atlassian.net/browse/AOP-5637
    window.addEventListener('vite:preloadError', () =>
      window.location.reload(),
    );
  }
};

export const loadEnvironment = async (target: string) => {
  switch (target) {
    case 'client':
      return (await import('./client-web')).default;
    case 'agent':
      return (await import('./agent-web')).default;
    default:
      throw new Error(`Invalid build target: ${target}`);
  }
};
