import { isClientPortal, isDev, isStage } from 'shared/utils/consts';

type TrackingConfig = {
  amplitudeApiKey: string;
  amplitudeSecretKey: string;
  hotjarID?: number;
  sentryDSN: string;
};

const isClient = isClientPortal();

const prod = {
  agent: {
    // Amplitude: https://app.amplitude.com/analytics/agentsonly/settings/projects/306254/general
    amplitudeApiKey: '1741788f91a8c01675112a84b298b82a',
    amplitudeSecretKey: 'cc3692e9456a005507a6a9de9029c83f',

    // Hotjar: https://insights.hotjar.com/sites/3006629/dashboard/
    hotjarID: 3006629,

    // Sentry: https://agents-only.sentry.io/settings/projects/ao-agent-web-prod/
    sentryDSN:
      'https://b4a2bbadd2bc4b5d973dc73d5116e83c@o946535.ingest.us.sentry.io/5896615',
  },
  client: {
    // Amplitude: https://app.amplitude.com/analytics/agentsonly/settings/projects/306253/general
    amplitudeApiKey: '9a3651827b9713f322c55a5219fb4fe9',
    amplitudeSecretKey: '16478566d238e703adf70868e32b8827',

    // Sentry: https://agents-only.sentry.io/settings/projects/ao-client-web-prod/
    sentryDSN:
      'https://85b5bbe1d0e542aa89b5df722e70f149@o946535.ingest.us.sentry.io/5895530',
  },
};

const stage = {
  agent: {
    // Amplitude: https://app.amplitude.com/analytics/agentsonly/settings/projects/670267/general
    amplitudeApiKey: '0d972949bc10b79ee67c61ee54662b09',
    amplitudeSecretKey: '6fcceac6d75fdd6f64786b86209208a1',

    // Hotjar: https://insights.hotjar.com/sites/5285729/dashboard/
    hotjarID: 5285729,

    // Sentry: https://agents-only.sentry.io/settings/projects/ao-agent-web-stage/
    sentryDSN:
      'https://853f60bd6bc092b9acb88db338444bab@o946535.ingest.us.sentry.io/4508729003147264',
  },
  client: {
    // Amplitude: https://app.amplitude.com/analytics/agentsonly/settings/projects/670269/general
    amplitudeApiKey: '892ab5f40130dcb61f1a2a2de9888c80',
    amplitudeSecretKey: 'b295270faa442d6ca69e9a7cec1c7853',

    // Sentry: https://agents-only.sentry.io/settings/projects/ao-client-web-stage/
    sentryDSN:
      'https://957ba4e106f8bb526cb513a73484c226@o946535.ingest.us.sentry.io/4508729039912960',
  },
};

const dev = {
  agent: {
    // Amplitude: https://app.amplitude.com/analytics/agentsonly/settings/projects/306252/general
    amplitudeApiKey: '6c9dbad8cf4b09a8e928183de73effb1',
    amplitudeSecretKey: '5cc8bb1970a76cb021b056c9a1864356',

    // Hotjar: https://insights.hotjar.com/sites/3057168/dashboard/
    hotjarID: 3057168,

    // Sentry: https://agents-only.sentry.io/settings/projects/ao-agent-web-dev/
    sentryDSN:
      'https://a93e843efe7f44fcb24c1357126892e4@o946535.ingest.us.sentry.io/5896612',
  },
  client: {
    // Amplitude: https://app.amplitude.com/analytics/agentsonly/settings/projects/306243/general
    amplitudeApiKey: '5026b694f3ab9920cb64ae8c1ab4fa04',
    amplitudeSecretKey: 'cb0d48b1cee3b345386a6906f2511091',

    // Sentry: https://agents-only.sentry.io/settings/projects/ao-client-web-dev/
    sentryDSN:
      'https://bd85dd15e6bb42f0859a069c04873efd@o946535.ingest.sentry.io/5895575',
  },
};

const environmentConfig = isDev() ? dev : isStage() ? stage : prod;

export const config: TrackingConfig = isClient
  ? environmentConfig.client
  : environmentConfig.agent;
