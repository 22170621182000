import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
} from 'shared/utils/consts';
import { init as initTracking } from 'shared/utils/tracking';

import { Auth0ProviderWithRedirectCallback } from './Auth0ProviderWithRedirectCallback';
import {
  configureSentry,
  disableConsoleInProd,
  loadEnvironment,
  setupGlobalDebugging,
} from './helpers';

(async () => {
  if (import.meta.env.REACT_APP_ENABLE_TRACKING) {
    configureSentry();
    initTracking();
  }

  disableConsoleInProd();
  setupGlobalDebugging();

  const target = import.meta.env.REACT_APP_BUILD_TARGET;
  const Environment = await loadEnvironment(target);
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    throw new Error('Root element not found');
  }

  createRoot(rootElement).render(
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          useRefreshTokens
          useRefreshTokensFallback
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: REACT_APP_AUTH0_API_AUDIENCE,
            scope: 'openid profile email',
          }}
        >
          <Environment />
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </React.StrictMode>,
  );
})();
